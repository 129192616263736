import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { EMAIL_VALIDATION_REG_EX } from '@constants';
import { Alert, Button, Input } from '@components';
import { Heading, Paragraph } from '@components/typography';
import recoverPassword from '@services/shopify/operations/recover-password';
import { MutationCustomerRecoverArgs } from '@ts/shopify-storefront-api';
import styles from './LoginForm.module.scss';

const RecoveryForm = ({ cancelRecovery }: { cancelRecovery: () => void }) => {
	const router = useRouter();

	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm<MutationCustomerRecoverArgs>({
		mode: 'all',
		defaultValues: {
			email: '',
		},
	});

	const [submitErrors, setSubmitErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const onSubmit: SubmitHandler<MutationCustomerRecoverArgs> = async ({ email }) => {
		try {
			setLoading(true);
			await recoverPassword(email);
			setSubmitErrors([]);
			router.reload();
		} catch (error) {
			console.error('Recovery form error: ', JSON.stringify(error));
			setLoading(false);
			reset();
			return setSubmitErrors(error.errors);
		}
	};

	const currentEmailInput = watch('email');
	const isValidEmail: boolean = EMAIL_VALIDATION_REG_EX.test(currentEmailInput);

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Button
				onClick={cancelRecovery}
				color='white'
				removeEffects
				withChevron
				chevronDirection='left'
				chevronPosition='front'
				label='Go Back'
				padding='0'
				border='0'
				linkStyle
				extraClasses={styles.backLink}
			/>
			<Heading tag='h2' className={styles.resetPassword}>
				Reset your password
			</Heading>
			<Paragraph style={{ fontSize: '1.8rem', marginBottom: '2.4rem' }} className={styles.loginParagraph}>
				We will send you an email to reset your password.
			</Paragraph>
			{!!submitErrors.length &&
				submitErrors.map(({ message }, index) => {
					return <Alert message={message.toString()} key={index} />;
				})}
			<Input
				{...register('email', {
					required: 'Please fill out this field.',
					pattern: {
						value: EMAIL_VALIDATION_REG_EX,
						message: `Please include an '@' in the email address. ${currentEmailInput} is missing an '@'.`,
					},
				})}
				errorMessage={errors?.email?.message}
				id='RecoveryCustomerEmail'
				name='email'
				placeholder='Email'
				type='email'
				withIcon
			/>

			<div className={styles.buttons}>
				<Button fullWidth label='Cancel' color='white' onClick={() => cancelRecovery()} />
				<Button showSpinner={loading} fullWidth type='submit' label='Submit' disabled={!isValidEmail} />
			</div>
		</form>
	);
};

export default RecoveryForm;
