import React from 'react';
import Img from '../Img';
import styles from './FormView.module.scss';

type FormViewProps = {
	Form: (args: unknown) => JSX.Element;
};
/**
 *	Renders a given Form Component side-by-side with an Image. The arrangement of the two children
 *  changes based on viewport width. E.g, Login Page, Refer-a-friend
 */
const FormView = ({ Form }: FormViewProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.formWrapper}>
				<Form />
			</div>
			<Img
				alt='side image'
				src={'https://cdn.shopify.com/s/files/1/1147/9910/files/778f9c5bee8dd2a3fe1950c164127405.jpg?v=1655930146'}
				width={1263}
				height={976}
			/>
		</div>
	);
};
export default FormView;
