import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { CustomerAccessTokenCreateInput, Mutation } from '@ts/shopify-storefront-api';
import { getDaysUntil } from '@utils/dates';
import { useCartContext } from '@context';
import { PairError, useCart } from '..';
import { setCustomerToken } from '../customer-token';
import fetchStorefrontApi from '../fetch-storefront-api';
import customerAccessTokenCreateMutation from '../mutations/customer-access-token-create';
import throwUserErrors from '../throw-user-errors';

type LoginProps = CustomerAccessTokenCreateInput & {
	queryClient?: QueryClient;
};

/**
 * React Hook for client-side creation of customer access token
 */
const useLogin = () => {
	const qc = useQueryClient();
	const { data: cart } = useCart();
	const { handleUpdateBuyer } = useCartContext();
	const growthbook = useGrowthBook();

	return async ({ email, password, queryClient = qc }: LoginProps) => {
		const loginSuccess = await login({ email, password });

		const accessToken = loginSuccess?.accessToken;
		const tokenExpiration = loginSuccess?.expiresAt;

		const daysUntilExpiration = getDaysUntil(new Date(Date.now()), new Date(tokenExpiration));

		if (accessToken) {
			growthbook.setAttributes({
				...growthbook.getAttributes(),
				email: email,
				loggedIn: true,
			});
			setCustomerToken(accessToken, daysUntilExpiration);
			queryClient && queryClient.resetQueries({ queryKey: ['customer'] });
			if (!!cart) {
				try {
					handleUpdateBuyer({ customerAccessToken: accessToken, email });
				} catch (error) {
					console.error(`Error adding customer to checkout: ${error}`);
				}
			}
		}
		return accessToken;
	};
};

/**
 *	Creates a token for accessing account data
 */
const login = async ({ email, password, queryClient = null }: LoginProps) => {
	if (!(email && password)) throw new PairError({ message: 'An email and password are required to login' });

	const mutation: Mutation = await fetchStorefrontApi(customerAccessTokenCreateMutation, {
		variables: { input: { email, password } },
	});

	const { customerAccessTokenCreate } = mutation;

	throwUserErrors(customerAccessTokenCreate?.customerUserErrors);

	const customerAccessToken = customerAccessTokenCreate?.customerAccessToken;

	return customerAccessToken;
};

export default useLogin;
